<template>
  <MainLayout>
    <template v-slot:navbar-title>
      Редактировать
    </template>

    <CreateFormLayout>
      <template v-slot:title__text>
        Группа объектов
      </template>

      <template v-slot:title-flex-right>
        <div></div>
      </template>

      <template>
        <!--    Данные компании    -->
        <div>
          <FormInputBlock>
            <ValidationInputField
              rules="required"
              v-model="name"
              label="Название"
              validate-name="название"
            />
            <ValidationAutocompleteField
              v-if="$store.getters.getCurrentRole !== 50"
              rules="required"
              v-model="service_organization"
              :search-function="serviceOrganizationSearch"
              label="Организация"
              :return-object="false"
              validate-name="организация"
            />
            <ValidationAutocompleteField
              rules="required"
              v-model="specialist"
              :search-function="specialistSearch"
              label="Техник"
              :return-object="false"
              validate-name="техник"
            />
          </FormInputBlock>

          <FormInputBlock>
            <ValidationAutocompleteField
              :value="listObjects"
              :search-function="objectSearch"
              multiple
              hide-selected
              label="Объекты"
              return-object
              @input="setEditAll($event)"
              validate-name="объекты"
            >
              <template v-slot:selection=""> </template
            ></ValidationAutocompleteField>
          </FormInputBlock>

          <table class="table table_overflow_hidden table-physical_cards">
            <thead>
              <tr>
                <th>Объект</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in listObjects"
                class="item"
                :key="index"
              >
                <td>{{ item.text }}</td>
                <td class="btn-delete-wrap">
                  <button
                    @click.stop="deleteEditObject(index)"
                    class="form__btn-delete"
                  ></button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!--    Данные компании    -->
      </template>
      <template v-slot:btns="{ handleSubmit }">
        <v-btn class="btn btn_color_white" @click="$router.go(-1)">
          Отмена
        </v-btn>
        <v-btn
          type="submit"
          @click="handleSubmit(editServiceGroup)"
          class="btn btn-green"
        >
          Сохранить
        </v-btn>
      </template>
    </CreateFormLayout>
    <v-overlay :value="loading" z-index="9999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </MainLayout>
</template>

<script>
import ValidationAutocompleteField from "@/components/ValidationField/ValidationAutocompleteField";
import ValidationInputField from "@/components/ValidationField/ValidationInputField";

import MainLayout from "@/components/layouts/MainLayout";
import CreateFormLayout from "@/components/layouts/CreateFormLayout";
import FormInputBlock from "@/components/createFormBlocks/FormInputBlock";
import { getObjectsRequestFromChildren } from "@/helpers/api/objects";
import { getUsersRequest } from "@/helpers/api/user";
import { getOrganizationsRequest } from "@/helpers/api/organizations";
import {
  actionMessage,
  successMessageDictionary
} from "@/helpers/dictionariesHelper/successMessages";

export default {
  name: "EditServiceGroupPage",
  components: {
    FormInputBlock,
    CreateFormLayout,
    MainLayout,

    ValidationInputField,
    ValidationAutocompleteField
  },

  data() {
    return {
      loading: false,
      object: null,

      objectSearch: async value => {
        return (
          await getObjectsRequestFromChildren({
            query: {
              ...this.$store.getters.getPageQuery.query,
              search: value
            }
          })
        ).data.results.map(el => ({
          text: el.name,
          value: el.id
        }));
      },

      serviceOrganizationSearch: async value => {
        return (
          await getOrganizationsRequest({
            query: {
              ids: value,
              types: "service"
            }
          })
        ).data.results.map(el => ({
          text: el.name,
          value: el.id
        }));
      },

      specialistSearch: async value => {
        const query = {
          ...this.$store.getters.getPageQuery.query,
          roles: 40
        };

        if (typeof value === "string") {
          query.search = value;
        } else {
          query.ids = value;
        }
        return (
          await getUsersRequest({
            query
          })
        ).data.results.map(el => ({
          text:
            el.last_name || el.first_name
              ? `${el.last_name} ${el.first_name}`
              : el.phone_number,
          value: el.id
        }));
      }
    };
  },
  computed: {
    name: {
      get() {
        return this.$store.getters.getServiceGroupEditForm.name.value;
      },
      set(newValue) {
        this.$store.commit("changeEditServiceGroupValue", {
          fieldName: "name",
          value: newValue
        });
      }
    },
    service_organization: {
      get() {
        return this.$store.getters.getServiceGroupEditForm.service_organization
          .value;
      },
      set(newValue) {
        this.$store.commit("changeEditServiceGroupValue", {
          fieldName: "service_organization",
          value: newValue
        });
      }
    },
    specialist: {
      get() {
        return this.$store.getters.getServiceGroupEditForm.specialist.value;
      },
      set(newValue) {
        this.$store.commit("changeEditServiceGroupValue", {
          fieldName: "specialist",
          value: newValue
        });
      }
    },
    served_objects: {
      get() {
        return this.$store.getters.getServiceGroupEditForm.served_objects.value;
      }
    },
    listObjects: {
      get() {
        return this.$store.getters.getServiceGroupEditForm.listObjects.value;
      }
    }
  },
  beforeDestroy() {
    this.$store.commit("clearCreateServiceTaskForm");
  },
  mounted() {
    this.$store.dispatch("prepareEditServiceGroup", {
      id: this.$route.params.id
    });
    if (this.$store.getters.getCurrentRole === 50) {
      this.service_organization = this.$store.getters.getCurrentOrganization;
    }
  },
  methods: {
    editServiceGroup() {
      this.$store
        .dispatch("editServiceGroup", { id: this.$route.params.id })
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.edit(successMessageDictionary.serviceGroup)
          );
        });
    },

    createServiceGroup() {
      this.loading = true;
      this.$store
        .dispatch("createServiceGroup")
        .finally(() => (this.loading = false));
    },
    deleteEditObject(number) {
      this.$store.commit("deleteEditServiceGroupObject", { number });
    },
    addEditObject(object) {
      this.$store.commit("addEditServiceGroupObject", { object });
      this.object = null;
    },
    setEditAll(objects) {
      this.$store.commit("setAllEditServiceGroupObjects", { objects });
    }
  }
};
</script>

<style scoped></style>
